// for login and logout
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const FETCH_TOKEN = 'FETCH_TOKEN';
export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const ATTEMPT_LOGIN_FAILED = 'ATTEMPT_LOGIN_FAILED';
export const RESET_LOGIN_FAILED = 'RESET_LOGIN_FAILED';
export const AFTER_USER_LOAD = 'AFTER_USER_LOAD';

// for error actions
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// firebase
export const LOAD_FIREBASE = "LOAD_FIREBASE";

// assets
export const GET_ASSETS = "GET_ASSETS";
export const ADD_ASSET = "ADD_ASSET";
export const DELETE_ASSET = "DELETE_ASSET";
export const ASSETS_LOADING = "ASSETS_LOADING";

// pages
export const  GET_PAGES = 'GET_PAGES';
export const  ADD_PAGE = 'ADD_PAGE';
export const  DELETE_PAGE = 'DELETE_PAGE';
export const  UPDATE_PAGE = 'UPDATE_PAGE';
export const PAGES_LOADING = 'PAGES_LOADING';
export const RESET_PAGE_CREATED = 'RESET_PAGE_CREATED';
export const RESET_PAGE_UPDATED = 'RESET_PAGE_UPDATED';
export const RESET_PAGE_DELETED = 'RESET_PAGE_DELETED';

// galleries
export const  GET_GALLERIES = 'GET_GALLERIES';
export const  ADD_GALLERY = 'ADD_GALLERY';
export const  DELETE_GALLERY = 'DELETE_GALLERY';
export const  UPDATE_GALLERY = 'UPDATE_GALLERY';
export const GALLERIES_LOADING = 'GALLERYS_LOADING';
export const RESET_GALLERY_CREATED = 'RESET_GALLERY_CREATED';
export const RESET_GALLERY_UPDATED = 'RESET_GALLERY_UPDATED';
export const RESET_GALLERY_DELETED = 'RESET_GALLERY_DELETED';

// mgmtprofiles
export const  GET_MGMTPROFILES = 'GET_MGMTPROFILES';
export const  ADD_MGMTPROFILE = 'ADD_MGMTPROFILE';
export const  DELETE_MGMTPROFILE = 'DELETE_MGMTPROFILE';
export const  UPDATE_MGMTPROFILE = 'UPDATE_MGMTPROFILE';
export const MGMTPROFILES_LOADING = 'MGMTPROFILES_LOADING';
export const RESET_MGMTPROFILE_CREATED = 'RESET_MGMTPROFILE_CREATED';
export const RESET_MGMTPROFILE_UPDATED = 'RESET_MGMTPROFILE_UPDATED';
export const RESET_MGMTPROFILE_DELETED = 'RESET_MGMTPROFILE_DELETED';

// accesses
export const  GET_ACCESSES = 'GET_ACCESSES';
export const  ADD_ACCESS = 'ADD_ACCESS';
export const  DELETE_ACCESS = 'DELETE_ACCESS';
export const  UPDATE_ACCESS = 'UPDATE_ACCESS';
export const ACCESSES_LOADING = 'ACCESSES_LOADING';
export const RESET_ACCESS_CREATED = 'RESET_ACCESS_CREATED';
export const RESET_ACCESS_UPDATED = 'RESET_ACCESS_UPDATED';
export const RESET_ACCESS_DELETED = 'RESET_ACCESS_DELETED';

// faqs
export const  GET_FAQS = 'GET_FAQS';
export const  ADD_FAQ = 'ADD_FAQ';
export const  DELETE_FAQ = 'DELETE_FAQ';
export const  UPDATE_FAQ = 'UPDATE_FAQ';
export const FAQS_LOADING = 'FAQS_LOADING';
export const RESET_FAQ_CREATED = 'RESET_FAQ_CREATED';
export const RESET_FAQ_UPDATED = 'RESET_FAQ_UPDATED';
export const RESET_FAQ_DELETED = 'RESET_FAQ_DELETED';

